import LinkList from './LinkList'
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import t from 'utils/typographie'
import { ms } from 'modules/browser/const'
import Container from 'theme/atoms/Container'
import { FaFacebookF, FaInstagram, FaPinterestP, FaTwitter } from 'react-icons/fa'

export default function Footer() {
  const gq = useStaticQuery(graphql`
    query FooterQuery {
      ueberWundercurves: allUeberWundercurvesLinks {
        nodes {
          rel
          relativeUrl
          shop
          label
        }
      }
      serviceLinks: allServiceLinks {
        nodes {
          rel
          relativeUrl
          shop
          label
        }
      }
      shopKategorienLinks: allShopKategorienLinks {
        nodes {
          rel
          relativeUrl
          shop
          label
        }
      }
    }
  `)
  const [showFooter, setShowFooter] = React.useState(true)

  React.useEffect(() => {
    if (window.location.pathname.startsWith('/app')) {
      setShowFooter(false)
    }
  }, [])

  return (
    <div className="Footer" data-cy-ctx="core/Footer">
      {/** eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state */}
      {/* {showFooter && <Newsletter /> } */}
      <Wrapper>
        <Container>
          <div className="top">
            <div className="box box-1">
              <div className="label">Folge Wundercurves</div>
              <div className="desc">
                Like unsere Page, tausch Dich mit anderen aus und werde sofort über neue
                Magazinartikel informiert!
              </div>
              <div className="icons">
                <SocialButton href="https://www.facebook.com/Wundercurvesde/" data-e3="facebook">
                  <FaFacebookF />
                </SocialButton>
                <SocialButton href="https://www.instagram.com/wundercurves.de/" data-e3="instagram">
                  <FaInstagram />
                </SocialButton>
                <SocialButton href="https://www.pinterest.com/wundercurves_de/" data-e3="pinterest">
                  <FaPinterestP />
                </SocialButton>
                <SocialButton href="https://twitter.com/wundercurves_de" data-e3="twitter">
                  <FaTwitter />
                </SocialButton>
              </div>
            </div>
            <div className="box box-2">
              <div className="label">Kurvensupport & Beratung</div>
              <div className="desc">
                Wir sind persönlich für Dich da! <br />
                Montag-Freitag 10-18 Uhr <br />
                wundercurves@kaminrun.de
                <br />
              </div>
            </div>
          </div>
          <div className="dropdowns">
            <div className="divider" />
            <LinkList label="Über Wundercurves" items={gq.ueberWundercurves.nodes} />
            <div className="divider" />
            <LinkList label="Service" items={gq.serviceLinks.nodes} />
            <div className="divider" />
            <LinkList label="Shopkategorien" items={gq.shopKategorienLinks.nodes} />
            <div className="divider" />
          </div>
          <div className="bottom">
            <div className="links">
              <Link to="/page/impressum/">Impressum</Link>
              <Link to="/page/datenschutz/">Datenschutzinformationen</Link>
            </div>
            <div className="copyright">© 2025 Kaminrunde & Freunde GmbH</div>
          </div>
        </Container>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.footer`
  background: #555555;
  color: white;
  padding: 15px 0;
  display: flex;

  @media (min-width: ${ms.LAPTOP_L}px) {
    padding: 25px 0;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: white;
    margin: 10px 0;
  }

  > .Container {
    > .top {
      display: flex;
      flex-wrap: wrap;

      > .box {
        flex: 1;
        flex-basis: 100%;
        margin-bottom: 15px;
        padding-right: 25px;

        > .label {
          ${t([11, 2.4, 20], 'Raleway', 'bold')}
          text-transform: uppercase;
        }
        > .desc {
          ${t([11, 0.33, 18], 'Open Sans')}
          margin-top: 4px;
          @media (min-width: ${ms.LAPTOP_L}px) {
            margin-top: 4px;
          }
        }
        > .icons {
          margin-top: 8px;
          @media (min-width: ${ms.LAPTOP_L}px) {
            margin-top: 16px;
          }
          display: flex;
          flex-wrap: wrap;
          > * {
            margin-right: 15px;
            color: white;
          }
          > .transparent {
            font-size: 40px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }

      @media (min-width: ${ms.TABLET}px) {
        > .box-1,
        .box-2 {
          flex-basis: 50%;
        }
      }

      @media (min-width: ${ms.LAPTOP_L}px) {
        > .box {
          flex-basis: 33%;
        }
      }
    }

    > .bottom {
      ${t([11, 2.4, 24], 'Raleway')}
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (min-width: ${ms.TABLET}px) {
        flex-direction: row;
        margin-top: 12px;
      }
      @media (min-width: ${ms.LAPTOP_XL}px) {
        margin-top: 25px;
      }
      > .links {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        @media (min-width: ${ms.LAPTOP}px) {
          margin-bottom: 0;
          flex-direction: row;
        }
        > a {
          margin-right: 12px;
          color: white;
        }
      }
    }
  }
`

const SocialButton = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  'data-e1': 'footer-nav',
  'data-e2': 'social-media',
}))`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid white;
`
