import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import { FiChevronRight } from 'react-icons/fi'
import * as actions from '../actions'
import LazyImg from 'theme/atoms/LazyImg'
import t from 'utils/typographie'

import Abendkleider from './images_new/Abendkleider.png'
import Waesche from './images_new/Waesche.png'
//import Bademode from './images_new/Bademode.png'
import Secondhand from './images_new/Secondhand.png'

type Props = {
  setComponent: (component: 'fashion' | 'guidance' | 'inspiration') => void
}

type Item = {
  label: string
  link: string
  component: 'fashion' | 'guidance' | 'inspiration' | null
  children?: {
    label: string
    image: string
    link: string
  }[]
}
const mainList: Item[] = [
  {
    label: 'Shop',
    link: '/shop/bekleidung/',
    component: 'fashion',
  },
  {
    label: 'Inspiration',
    link: '/page/looks/',
    component: 'inspiration',
  },
  {
    label: 'Beratung',
    link: '/page/beratung-grosse-groessen/',
    component: 'guidance',
  },
  {
    label: 'Highlights',
    link: '',
    component: null,
    children: [
      {
        label: 'Abendkleider',
        image: Abendkleider,
        link: '/shop/bekleidung/kleider/abendkleider/',
      },
      {
        label: 'Wäsche',
        image: Waesche,
        link: '/shop/bekleidung/waesche-und-shapewear/',
      },
      // {
      //   label: 'Bademode',
      //   image: Bademode,
      //   link: '/shop/bekleidung/bademode-und-strandkleidung/',
      // },
      {
        label: 'Second Hand',
        image: Secondhand,
        link: '/page/second-hand-mode-grosse-groessen/',
      },
    ],
  },
]


export default function RootNavigation(props: Props) {
  const trackClick = (label) => () => {
    actions.clickItem(label)
  }

  return (
    <Wrapper className="RootNavigation" data-cy-collection="RootNavigation">
      {mainList.map((row) => (
        <div key={row.link}>
          <Link
            to={row.link}
            className="link"
            onClick={(e) => {
              trackClick(row.label)()
              if (!row.component) return
              e.preventDefault()
              props.setComponent(row.component)
            }}
            data-cy-handle="list-item-btn"
          >
            <div className="left" />
            <div className="label">{row.label}</div>
            <div className="right">
              {row.component && <FiChevronRight data-cy-state="has-children" />}
            </div>
          </Link>
          {row.children && (
            <div className="sub-links" data-cy-state="has-highlights">
              {row.children?.map((row) => (
                <Link to={row.link} key={row.label}>
                  <LazyImg
                    alt=""
                    src={row.image}
                    default={
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAIAAACjcKk8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAHElEQVQ4y2P4TwFgGNU8qnlU86jmUc2jmoezZgD1FQFieFs2lwAAAABJRU5ErkJggg=='
                    }
                  />
                  <div className="label">{row.label}</div>
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 16px;
  .link {
    background: white;
    margin-bottom: 15px;
    display: flex;
    text-decoration: none;
    color: #555555;
    cursor: pointer;
    > .left {
      width: 20px;
    }
    > .right {
      width: 30px;
    }
    > .label {
      flex: 1;
      text-transform: uppercase;
      padding: 15px 0;
      color: #555555;
      font-family: Raleway;
      font-size: 15px;
      letter-spacing: 1.7px;
      line-height: 25px;
    }
    > .right {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }

  .sub-links {
    background: white;
    margin-top: -15px;
    margin-bottom: 15px;
    > a {
      display: flex;
      margin: 0 12px;
      padding: 11px 0;
      border-top: 1px solid #eeeced;
      text-decoration: none;
      > img {
        width: 28px;
        height: 28px;
        border-radius: 28px;
        margin-left: 3px;
      }
      > .label {
        margin-left: 10px;
        color: #555555;
        text-transform: uppercase;
        ${t([13, 1.3, 28], 'Raleway')};
      }
    }
  }
`
